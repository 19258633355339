import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import * as text from '../../styles/text'

const defaultStyle = css`
  color: ${props =>
    props.light ? props.theme.colors.light : props.theme.colors.grayDark};
  ${props => (props.fat ? 'font-weight: 900;' : '')}
  ${props => (props.block ? 'display: block;' : '')}
`

export const Copy = styled.span`
  ${defaultStyle}
  ${text.copy}
  ${space}
  
`
export const Small = styled.span`
  ${defaultStyle}
  ${text.small}
  ${space}
`

export const FatCopy = styled(Copy)`
  ${text.fat}
  ${space}
`

export const Headline = styled.span`
  ${defaultStyle}
  ${text.headline}
  ${space}
`
export const H1 = styled.span`
  ${defaultStyle}
  ${text.h1}
  ${space}
`

export const H2 = styled.span`
  ${defaultStyle}
  ${text.h2}
  ${space}
`
export const H3 = styled.span`
  ${defaultStyle}
  ${text.h3}
  ${space}
`

export const Badge = styled.span`
  ${defaultStyle}
  ${text.badge}
  ${space}
`

export const MenuText = styled.span`
  ${defaultStyle}
  ${text.menu}
  ${space}
`

export default Copy
