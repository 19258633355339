import { css } from 'styled-components'
import media from './media'

export const headline = css`
  font-family: ${props => props.theme.fontFamilySecondary};
  color: ${props => props.theme.colors.grayDark};
  font-weight: 700;

  font-size: 4.8rem;
  line-height: 5.2rem;

  ${media.up.sm`
  font-size: 6.4rem;
  line-height: 6.4rem;
  `}
`

export const h1 = css`
  font-weight: bold;
  font-size: 2.4rem;
  color: ${props => props.theme.colors.grayDark};
  line-height: 3.8rem;

  ${media.up.sm`
    font-size: 3rem;
  `}
`

export const h2 = css`
  font-weight: 300;
  font-size: 2rem;
  color: ${props => props.theme.colors.grayDark};
  line-height: 4rem;
  ${media.up.sm`
  font-size: 2.2rem;
  line-height: 1;
  `};
`

export const h3 = css`
  font-weight: 700;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.grayDark};
  line-height: 2.5rem;

  ${media.up.sm`
  font-size: 1.8rem;
  line-height: 2.7rem;
  `}
`

export const badge = css`
  font-weight: 700;
  line-height: 1;
  color: ${props => props.theme.colors.grayDark};
  font-size: 1.4rem;
  ${media.up.sm`
    font-size: 1.6rem;
  `}
`

export const copy = css`
  font-weight: 300;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.grayDark};
  line-height: 2.4rem;

  ${media.up.sm`
  font-size: 1.6rem;
  line-height: 2.6rem;
  `}
`
export const small = css`
  font-weight: 700;
  font-size: 2rem;
  color: ${props => props.theme.colors.grayDark};
  line-height: 2.8rem;
`

export const fat = css`
  font-weight: 700;
`

export const menu = css`
  font-weight: 700;
  font-size: 1.8rem;
  color: {props => props.theme.color.grayDark};
  letter-spacing: 0;
  line-height: 3.2rem;
  ${media.down.sm`
    font-size: 1.6rem;
  `}
`
